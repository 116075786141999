<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <main v-if="is_API_loaded || isNewCollection" class="router-view-container space-y-4">
        <!-- 1. -->
        <section class="flex items-center space-x-4 justify-between">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <!-- 如果是新增分類，就隱藏此區塊 -->
          <div v-if="!isNewCollection" class="flex lg:w-full items-center justify-between">
            <h1 class="text-xl font-medium text-gray-text_dark hidden lg:block">{{ title }}</h1>
            <div class="flex items-center space-x-2 flex-shrink-0">
              <a :href="_wlink" target="_blank">
                <vs-button size="large" transparent>
                  <div class="flex items-center text-gray-text_light">
                    <svg-icon icon-class="eye_view" className="gray-text-light xl"></svg-icon>
                    <span>檢視</span>
                  </div>
                </vs-button>
              </a>
            </div>
          </div>
        </section>
        <h1 v-if="!isNewCollection" class="text-xl font-medium text-gray-text_dark lg:hidden">{{ title }}</h1>

        <!-- 新增分類後顯示 -->
        <transition name="fade">
          <section v-if="alertAfterCreated">
            <vs-alert color="success" gradient>
              <template #icon>
                <svg-icon icon-class="success" className="xxl"></svg-icon>
              </template>
              <template #title> {{ alertTitle }} 已新增成功 </template>
              <a :href="_wlink" target="_blank">在前台中檢視</a> 或 <router-link :to="{ name: 'menus' }">加入目錄選單</router-link>
            </vs-alert>
          </section>
        </transition>

        <!-- 2. -->
        <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
          <!-- 2.1.1 -->
          <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
            <article class="box">
              <div>
                <h1 class="title">標題 *</h1>
                <div class="relative">
                  <ValidationProvider mode="aggressive" name="標題" rules="required|max:50" v-slot="{ errors }">
                    <vs-input @input="titleChange" class="w-full" v-model="title" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>

              <div>
                <h1 class="title">內容</h1>
                <editor ref="editor" v-model="content" :content="content" @input="contentChange"></editor>
              </div>
            </article>

            <!-- 2.1.2 SEO -->
            <article class="box overflow-hidden" :class="[seoSectionOpen ? '' : 'h-16']">
              <div class="flex items-center justify-between">
                <h1 class="bigTitle">SEO 搜尋引擎最佳化</h1>
                <vs-button v-if="!seoSectionOpen" @click="seoSectionOpen = true" transparent color="success">編輯 SEO</vs-button>
              </div>

              <section
                class="transition-transform duration-100 transform scale-y-0 origin-top space-y-6"
                :class="[seoSectionOpen ? 'scale-y-100' : '']"
              >
                <div class="space-y-1">
                  <h1 class="text-success font-medium text-base">{{ seoTitle }}</h1>
                  <p class="text-green-700 text-sm break-all" v-if="seoUrl">
                    {{ $store.state.getWebData.param.wlink }}/collections/{{ seoUrl }}
                  </p>
                  <p class="text-sm text-limit-2" style="max-height: 2.5em">{{ _seoContent }}</p>
                </div>
                <div>
                  <h1 class="title">網頁標題</h1>
                  <ValidationProvider mode="aggressive" name="網頁標題" rules="max:100" v-slot="{ errors }">
                    <vs-input class="w-full" v-model="seoTitle" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div>
                  <h1 class="title">網頁網址</h1>
                  <div class="flex items-center border border-gray-border rounded-xl pl-3">
                    <div>
                      <span>{{ $store.state.getWebData.param.wlink }}/collections/</span>
                    </div>
                    <ValidationProvider class="w-full" mode="aggressive" name="網頁網址" rules="max:100" v-slot="{ errors }">
                      <vs-input type="url" id="noBorderInput" class="w-full" v-model.trim="seoUrl" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </div>

                <div>
                  <h1 class="title">網頁描述</h1>

                  <ValidationProvider class="w-full" mode="aggressive" name="網頁描述" rules="max:200" v-slot="{ errors }">
                    <myTextarea autoHeight v-model="_seoContent"></myTextarea>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </section>
            </article>
          </div>

          <!-- 2.2.1 封面圖片 -->
          <div class="flex-grow space-y-4">
            <div class="box">
              <h1 class="bigTitle">封面圖片</h1>
              <div class="upload aspect-ratio-1-1_global_style">
                <img v-if="img" :src="img" class="uploadImg" />
                <div v-else class="upload_icon">
                  <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                  <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                </div>
                <input type="file" @change="uploadImg" accept="image/*" class="img_input" />
              </div>
              <vs-button v-if="img" @click="img = ''" transparent danger style="margin-right: 0; margin-left: auto">移除</vs-button>
            </div>
            <!-- 2.2.2 在此分類中的商品 (新增時不顯示) -->
            <div class="box" v-if="!isNewCollection">
              <div class="flex items-center justify-between mb-4">
                <h1 class="bigTitle">在此分類中的商品</h1>
                <router-link
                  v-if="products.length > 0"
                  :to="{
                    name: 'products',
                    query: {
                      collections: $route.params.id,
                    },
                  }"
                >
                  <vs-button transparent color="success" size="mini"><span class="text-sm">查看全部</span></vs-button></router-link
                >
              </div>
              <div class="space-y-2" v-if="products.length > 0">
                <div v-for="(item, index) in products" :key="index" class="flex items-center space-x-2">
                  <div
                    class="w-10 h-10 items-center justify-center flex border border-gray-border rounded-lg overflow-hidden flex-shrink-0"
                  >
                    <img v-if="item.img" class="w-full h-full object-cover" :src="item.img" />
                    <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                  </div>
                  <p class="">{{ item.title }}</p>
                </div>
              </div>
              <p v-else class="text-gray-divide text-center py-5">尚無任何商品</p>
            </div>
          </div>
        </section>

        <hr />

        <section class="flex items-center justify-between">
          <vs-button @click="delete_dialog = true" v-if="!isNewCollection" size="large" color="danger" border>
            <span class="font-medium">刪除分類</span>
          </vs-button>
          <vs-button to="/admin/pages" v-else size="large" border>
            <span class="font-medium">取消</span>
          </vs-button>
          <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="saveCollection(invalid)">
            <span class="font-medium">儲存分類</span>
          </vs-button>
        </section>
      </main>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded && !isNewCollection" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNewCollection" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNewCollection" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除分類 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此分類？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的分類無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteCollection" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import editor from '@/components/editor.vue'
export default {
  name: 'collection',
  components: {
    editor,
  },
  data() {
    return {
      is_API_loaded: false,
      isNewCollection: false,
      title: '',
      content: '',
      seoTitle: '',
      seoUrl: '',
      seoContent: '',
      seoSectionOpen: false,
      titleSync: true,
      contentSync: true,
      urlSync: true,
      delete_dialog: false,
      alertAfterCreated: false, // 新增成功後呈現alert訊息
      alertTitle: '',
      img: '',
      wlink: '',
      products: [],
    }
  },
  computed: {
    _seoContent: {
      get: function () {
        return this.seoContent
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      },
      set: function (val) {
        this.seoContent = val
      },
    },
    _wlink() {
      return this.$store.state.getWebData.param.wlink + this.wlink
    },
  },
  watch: {
    $route(to, from) {
      this.isNewCollectionFunc()
    },
    seoTitle(val) {
      if (val !== this.title) this.titleSync = false
      if (val === '') {
        // this.seoTitle = this.title
        this.titleSync = true
      }
    },
    seoUrl(val) {
      if (val !== this.title || this.isNewCollection === false) this.urlSync = false
      if (val === '') {
        // this.seoUrl = this.title
        this.urlSync = true
      }
    },
    seoContent(val) {
      if (val !== this.content) this.contentSync = false
      if (val === '') {
        // this.seoContent = this.content
        this.contentSync = true
      }
    },
  },
  created() {
    this.isNewCollectionFunc()
  },
  methods: {
    isNewCollectionFunc() {
      // 判斷是新增分類 還是 編輯分類
      this.$route.name === 'new-collection' ? (this.isNewCollection = true) : (this.isNewCollection = false)

      if (!this.isNewCollection) this.loadCollection()
    },
    titleChange(value) {
      // 若sync = true，賦值到seo
      if (this.titleSync) this.seoTitle = value
      if (this.urlSync) this.seoUrl = value
    },
    // 3.1.3.2 分類資料-讀取
    loadCollection() {
      this.$axios({
        url: 'front/store/type/getTypeUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sptno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data
          this.title = data.title
          this.content = data.cont
          this.img = data.img
          this.seoTitle = data.mtitle
          this.seoContent = data.mdescs
          this.seoUrl = data.mpages
          this.wlink = data.wlink
          this.products = data.pitem
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.1.3.3 分類資料-新增
    // 3.1.3.4 分類資料-修改
    saveCollection(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = ''

      let mdescs = '' // seo網頁描述
      if (this._seoContent === '') {
        // 若seo網頁描述被清空，把內容塞回去
        mdescs = this.content
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      } else {
        mdescs = this._seoContent
      }

      let mpages = '' // 網頁網址
      if (this.seoUrl === '') {
        mpages = this.title.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      } else {
        mpages = this.seoUrl.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      }

      let data = {
        lang: 0,
        title: this.title,
        img: this.img,
        cont: this.content,
        mtitle: this.seoTitle ? this.seoTitle : (this.seoTitle = this.title),
        mdescs: mdescs,
        mpages: mpages,
      }

      if (this.isNewCollection) {
        url = 'front/store/type/setTypeData.php'
      } else {
        url = 'front/store/type/uTypeData.php'
        data.sptno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()

          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNewCollection) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.title
            this.$router.replace({
              // 跳轉到新頁面
              name: 'edit-collection',
              params: {
                id: res.data.Data.sptno,
              },
            })
          } else {
            this.loadCollection()
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 2.5.5 分類資料-刪除
    deleteCollection() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/type/dTypeData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sptno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.$router.replace({
            name: 'collections',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    uploadImg(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.img = e.target.result
      }
      e.target.value = ''
    },
    contentChange(value) {
      // 若sync = true，賦值到seo
      if (this.contentSync) this.seoContent = value
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

// seo網址
::v-deep #vs-input--noBorderInput {
  border: none;
  padding-left: 0px;
}

.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
  &.aspect-ratio-1-1_global_style {
    padding-bottom: calc(100% - 4px);
  }
}

.uploadImg {
  @apply absolute top-1/2 transform -translate-y-1/2;
  width: 100%;
  @screen md {
    max-width: 261px;
  }
}

.upload_icon {
  @apply flex flex-col items-center absolute top-1/2 transform -translate-y-1/2;
}

.img_input {
  @apply border-2 absolute z-10 h-full w-full cursor-pointer opacity-0 top-1/2 transform -translate-y-1/2;
}
</style>
